/*Privacy page*/
.privacy-banner {
  background-image: url("../../images/IMG_5096.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 8% 0%;
}
.col-lg-12.privacy-Banner h1 {
  color: #fff;
  text-transform: uppercase;
}
.container.content {
  padding: 80px 0px;
}
.container.content h3 {
  padding-top: 15px;
}
