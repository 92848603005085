@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap); /**
 * Voler
 *
 * Voler is a clean & modern HTML5 admin template based on Bootstrap 5
 * Voler will make it easier for you to create your own admin interface.
 *
 * @package	Voler
 * @version	0.1
 * @author 	Ahmad Saugi
 * @url 	https://github.com/zuramai/voler
 * 
 */
/**
 * TABLE OF CONTENTS
 *
 * 1. Variables
 * 2. Bootstrap Override
 * 	  2.1 Alert
 * 	  2.2 Avatar
 * 	  2.3 Badge
 * 	  2.4 Buttons
 * 	  2.5 Breadcrumb
 * 	  2.6 Carousel
 * 	  2.7 Divider
 * 	  2.8 Dropdowns
 * 	  2.9 Forms
 * 	  2.10 Modal
 * 	  2.11 Sidebar
 * 	  2.12 Card
 * 	  2.13 Navs
 * 	  2.14 Navbar
 * 	  2.15 Pagination
 * 	  2.16 Table
 * 	  2.17 Progress
 * 4. Pages
 * 	  4.1 Auth
 * 	  4.2 Error
 * 5. Utilities
 */
/** 1. Variables  **/
/**
 * VARIABLE - TABLE OF CONTENTS
 * 
 * 1. Theme Colors
 * 2. Bootstrap Override
 * 	  2.4 Alert
 * 	  2.11 Badge
 * 	  2.12 Button
 * 	  2.14 Breadcrumb
 * 	  2.1 Card
 * 	  2.2 Form
 * 	  2.3 List
 * 	  2.5 Progress
 * 	  2.6 Table
 * 	  2.7 Tooltip
 * 	  2.8 Modal
 * 	  2.9 Nav
 * 	  2.10 Pagination
 * 	  2.13 Media
 * 	  2.15 Accordion
 * 	  2.16 Popover
 * 	  2.17 Grid
 * 	  2.18 Navbar
 * 	  2.19 Dropdown
 * 	  2.20 Tab
 * 	  2.21 Progressbar
 * 	  2.22 Jumbotron
 * 	  2.23 Carousel
 * 5. Utilities
 * 6. Responsive
 */
.color-primary {
  color: #5a8dee;
}

.color-secondary {
  color: #475f7b;
}

.color-success {
  color: #39da8a;
}

.color-danger {
  color: #ff5b5c;
}

.color-warning {
  color: #fdac41;
}

.color-info {
  color: #00cfdd;
}

.color-dark {
  color: #222f3e;
}

.color-light {
  color: #a3afbd;
}

.color-link {
  color: #2178d1;
}

#app {
  position: relative;
}

#main {
  margin-left: 260px;
  background-color: #c5e2e0;
  min-height: 100vh;
  transition: margin-left 0.6s ease-out;
}
#main .main-content {
  padding: 2rem 1.5rem;
}
#main .main-content .page-title h3 {
  font-size: 2rem;
}
#main .main-content .text-subtitle {
  font-size: 0.9rem;
}

.breadcrumb-header .breadcrumb {
  justify-content: flex-end;
}

.alert.alert-dismissible .close:focus,
.custom-switch .custom-control-label:focus,
.dropdown .dropdown-toggle:focus,
.dropleft .dropdown-toggle:focus,
.dropright .dropdown-toggle:focus,
.dropup .dropdown-toggle:focus,
.list-group button.list-group-item:focus,
.modal .modal-content .modal-header .close:active,
.modal .modal-content .modal-header .close:focus,
.modal .modal-content .modal-header .close:hover,
.navbar-components-wrapper
  .navbar
  .navbar-container.navbar-dark
  .navbar-toggler:focus,
.navbar-components-wrapper
  .navbar
  .navbar-container.navbar-light
  .navbar-toggler:focus,
.toast .toast-header .close:active,
.toast .toast-header .close:focus,
a:focus {
  outline: 0;
}

section .section-title:after {
  width: 70px;
  height: 2px;
  content: "";
  margin: 0.6rem 0 1rem;
  background-color: #5a8dee;
  display: block;
}

a {
  text-decoration: none;
  outline: 0;
}

button {
  outline: 0;
}

svg.feather,
i {
  font-size: 14px;
  height: 1rem;
}

footer {
  padding: 1rem 1.5rem;
}

@media screen and (max-width: 767px) {
  #main {
    margin-left: 0;
  }

  .breadcrumb-header .breadcrumb {
    justify-content: flex-start;
    padding: 0;
    font-size: 0.8rem;
  }
}
/** 2.1 Alert  **/
.alert {
  border: none;
}
.alert p {
  margin-bottom: 0;
}
.alert button.close {
  padding: 0.75rem;
  line-height: 0.75;
}
.alert .alert-heading {
  margin-left: 0.4rem;
}
.alert .alert-heading + p {
  margin-left: 0.4rem;
}

.alert-primary {
  background-color: #029eff;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(2, 158, 255, 0.3);
}
.alert-primary a {
  color: #fff;
}

.alert-secondary {
  background-color: #ebeef3;
  color: #383d41;
  box-shadow: 1px 2px 9px rgba(235, 238, 243, 0.3);
}
.alert-secondary a {
  color: #fff;
}

.alert-success {
  background-color: #39da8a;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(57, 218, 138, 0.3);
}
.alert-success a {
  color: #fff;
}

.alert-warning {
  background-color: #fdd347;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(253, 211, 71, 0.3);
}
.alert-warning a {
  color: #fff;
}

.alert-danger {
  background-color: #f55260;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(245, 82, 96, 0.3);
}
.alert-danger a {
  color: #fff;
}

.alert-dark {
  background-color: #454546;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(69, 69, 70, 0.3);
}
.alert-dark a {
  color: #fff;
}

.alert-light {
  background-color: #fefefe;
  color: #818182;
  box-shadow: 1px 2px 9px rgba(254, 254, 254, 0.3);
}
.alert-light a {
  color: #fff;
}

.alert-info {
  background-color: #56b6f7;
  color: #fff;
  box-shadow: 1px 2px 9px rgba(86, 182, 247, 0.3);
}
.alert-info a {
  color: #fff;
}

.alert-light-primary {
  background-color: #e2ecff;
}

.alert-light-secondary {
  background-color: #e6eaee;
}

.alert-light-success {
  background-color: #d2ffe8;
}

.alert-light-danger {
  background-color: #ffdede;
}

.alert-light-warning {
  background-color: #ffeed9;
}

.alert-light-info {
  background-color: #ccf5f8;
}

/** 2.2 Avatar **/
.avatar {
  display: inline-flex;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
.avatar .avatar-content {
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}
.avatar .avatar-content svg,
.avatar .avatar-content i {
  color: #fff;
  font-size: 1rem;
  height: 1rem;
}
.avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.avatar .avatar-status {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #fff;
  bottom: 0;
  right: 0;
}
.avatar.avatar-sm .avatar-content,
.avatar.avatar-sm img {
  width: 24px;
  height: 24px;
  font-size: 0.8rem;
}
.avatar.avatar-lg .avatar-content,
.avatar.avatar-lg img {
  width: 48px;
  height: 48px;
  font-size: 1.2rem;
}
.avatar.avatar-xl .avatar-content,
.avatar.avatar-xl img {
  width: 60px;
  height: 60px;
  font-size: 1.4rem;
}

/** 2.3 Badge **/
.btn .badge {
  border-radius: 50%;
  margin-left: 5px;
}
.btn .badge.bg-transparent {
  background-color: rgba(255, 255, 255, 0.25) !important;
  color: #fff;
}

/** 2.4 Buttons **/
.btn i,
.btn svg {
  width: 16px;
  height: 16px;
}
.btn.icon {
  padding: 0.4rem 0.6rem;
}
.btn.icon svg {
  width: 16px;
  height: 16px;
}
.btn.icon.icon-left svg {
  margin-right: 3px;
}
.btn.icon.icon-right svg {
  margin-left: 3px;
}
.btn.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn.btn-outline-white:hover {
  color: #333;
  background-color: #fff;
}
.btn.btn-primary {
  color: #fff;
}
.btn.btn-secondary {
  color: #fff;
}
.btn.btn-success {
  color: #fff;
}
.btn.btn-danger {
  color: #fff;
}
.btn.btn-warning {
  color: #fff;
}
.btn.btn-info {
  background-color: #4f0170 !important;
  color: #fff;
}
.btn.btn-info:disabled {
  background-color: #65bcbf !important;
  color: #fff;
}
.btn.btn-dark {
  color: #fff;
}
.btn.btn-light {
  color: #fff;
}
.btn.btn-link {
  color: #fff;
}

.btn-group:not(.dropdown) .btn:not([class*="btn-"]) {
  border: 1px solid #dfe3e7;
}
.btn-group > .btn {
  border-radius: 0.267rem;
}
.buttons .btn {
  margin: 0 10px 10px 0;
}

/** 2.5 Breadcrumb **/
.breadcrumb.breadcrumb-right {
  justify-content: flex-end;
  margin-top: 1rem;
}
.breadcrumb.breadcrumb-center {
  justify-content: center;
  margin-top: 1rem;
}

/** 2.6 Carousel **/
.carousel-inner {
  border-radius: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 30px;
  /* box-shadow: 0 5px 10px #adb5bd; */
}

.carousel-caption h5 {
  color: #fff;
}

/** 2.7 Divider **/
.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  margin: 1rem 0;
}
.divider .divider-text {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
  background-color: #fff;
}
.divider .divider-text:before,
.divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #dfe3e7;
}
.divider .divider-text:before {
  right: 100%;
}
.divider .divider-text:after {
  left: 100%;
}
.divider.divider-left .divider-text {
  left: 0;
  float: left;
  padding-left: 0;
}
.divider.divider-left-center .divider-text {
  left: -25%;
}
.divider.divider-right-center .divider-text {
  left: 25%;
}
.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}

/** 2.8 Dropdowns **/
.dropdown-toggle:after {
  color: #fff;
}

.dropdown-menu-large {
  min-width: 16rem;
}

/** 2.9 Forms **/
.form-group {
  margin-bottom: 0.7rem;
}
.form-group label {
  font-size: 0.755rem;
  text-transform: uppercase;
  color: rgba(35, 28, 99, 0.7);
  font-weight: 500;
}
.form-group small {
  font-size: 0.7rem;
}
.form-group.with-title {
  position: relative;
}
.form-group.with-title label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 0.6rem;
  background-color: #e9ecef;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group.with-title .form-control,
.form-group.with-title dataTable-input {
  padding-top: 2rem;
}
.form-group.with-title .form-control:focus ~ label,
.form-group.with-title dataTable-input:focus ~ label {
  border-left: 1px solid #5a8dee;
  border-top: 1px solid #5a8dee;
  border-right: 1px solid #5a8dee;
}
.form-group[class*="has-icon-"].has-icon-left .form-control {
  padding-left: 2.5rem;
}
.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
  left: 0;
}
.form-group[class*="has-icon-"].has-icon-right .form-control {
  padding-right: 2.5rem;
}
.form-group[class*="has-icon-"].has-icon-right .form-control-icon {
  right: 0;
}
.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon i,
.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon svg {
  color: #5a8dee;
}
.form-group[class*="has-icon-"] .form-control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0.6rem;
}
.form-group[class*="has-icon-"] .form-control-icon i,
.form-group[class*="has-icon-"] .form-control-icon svg {
  width: 1.2rem;
}

.form-check .form-check-input[class*="bg-"] {
  border: 0;
}
.form-check .form-check-input:focus {
  box-shadow: none;
}
.form-check .form-check-input.form-check-primary {
  background-color: #5a8dee;
  border-color: #5a8dee;
}
.form-check .form-check-input.form-check-primary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-primary.form-check-glow {
  box-shadow: 0 0 5px #88adf3;
}
.form-check .form-check-input.form-check-primary.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-secondary {
  background-color: #475f7b;
  border-color: #475f7b;
}
.form-check .form-check-input.form-check-secondary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-secondary.form-check-glow {
  box-shadow: 0 0 5px #5a789b;
}
.form-check
  .form-check-input.form-check-secondary.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-success {
  background-color: #39da8a;
  border-color: #39da8a;
}
.form-check .form-check-input.form-check-success:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-success.form-check-glow {
  box-shadow: 0 0 5px #64e2a3;
}
.form-check .form-check-input.form-check-success.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-danger {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}
.form-check .form-check-input.form-check-danger:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-danger.form-check-glow {
  box-shadow: 0 0 5px #ff8e8f;
}
.form-check .form-check-input.form-check-danger.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-warning {
  background-color: #fdac41;
  border-color: #fdac41;
}
.form-check .form-check-input.form-check-warning:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-warning.form-check-glow {
  box-shadow: 0 0 5px #fec273;
}
.form-check .form-check-input.form-check-warning.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-info {
  background-color: #00cfdd;
  border-color: #00cfdd;
}
.form-check .form-check-input.form-check-info:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-info.form-check-glow {
  box-shadow: 0 0 5px #11f0ff;
}
.form-check .form-check-input.form-check-info.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-dark {
  background-color: #222f3e;
  border-color: #222f3e;
}
.form-check .form-check-input.form-check-dark:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-dark.form-check-glow {
  box-shadow: 0 0 5px #34485f;
}
.form-check .form-check-input.form-check-dark.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-light {
  background-color: #a3afbd;
  border-color: #a3afbd;
}
.form-check .form-check-input.form-check-light:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-light.form-check-glow {
  box-shadow: 0 0 5px #c1c9d2;
}
.form-check .form-check-input.form-check-light.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-link {
  background-color: #2178d1;
  border-color: #2178d1;
}
.form-check .form-check-input.form-check-link:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-link.form-check-glow {
  box-shadow: 0 0 5px #4492e1;
}
.form-check .form-check-input.form-check-link.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check.form-check-sm .form-check-input {
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 0.3em;
}
.form-check.form-check-sm label {
  font-size: 0.7rem;
}
.form-check.form-check-primary .form-check-input {
  background-color: #5a8dee;
  border-color: #5a8dee;
}
.form-check.form-check-secondary .form-check-input {
  background-color: #475f7b;
  border-color: #475f7b;
}
.form-check.form-check-success .form-check-input {
  background-color: #39da8a;
  border-color: #39da8a;
}
.form-check.form-check-danger .form-check-input {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
}
.form-check.form-check-warning .form-check-input {
  background-color: #fdac41;
  border-color: #fdac41;
}
.form-check.form-check-info .form-check-input {
  background-color: #00cfdd;
  border-color: #00cfdd;
}
.form-check.form-check-dark .form-check-input {
  background-color: #222f3e;
  border-color: #222f3e;
}
.form-check.form-check-light .form-check-input {
  background-color: #a3afbd;
  border-color: #a3afbd;
}
.form-check.form-check-link .form-check-input {
  background-color: #2178d1;
  border-color: #2178d1;
}

.dataTable-input {
  min-height: calc(1.5em + 0.934rem + 2px);
  padding: 0.467rem 0.6rem;
  font-size: 0.9025rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555252;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dfe3e7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dataTable-input:focus {
  color: #555252;
  background-color: white;
  border-color: #5a8dee;
  outline: 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

/** 2.10 Modal **/
.modal .modal-content {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  border: none;
}
.modal .modal-full {
  max-width: 94%;
}
.modal .white {
  color: white;
}
.modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal .modal-header .modal-title {
  font-size: 1.1rem;
}
.modal .modal-header .close {
  padding: 7px 10px;
  border-radius: 50%;
}
.modal .modal-header .close:hover {
  background: #dee2e6;
}
.modal .modal-header i,
.modal .modal-header svg {
  font-size: 12px;
  height: 12px;
  width: 12px;
}
.modal .modal-footer {
  padding: 1rem 2rem;
}
.modal.modal-borderless .modal-header {
  border-bottom: 0;
}
.modal.modal-borderless .modal-footer {
  border-top: 0;
}

/** 2.11 Sidebar **/
#sidebar.active .sidebar-wrapper {
  left: 0;
}
#sidebar:not(.active) ~ #main {
  margin-left: 0;
}

.sidebar-wrapper {
  width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
  bottom: 0;
  border-right: 1px solid #eee;
  box-shadow: 0 0 10px #ececec;
  left: -260px;
  transition: left 0.6s ease-out;
}
.sidebar-wrapper .sidebar-header {
  padding: 2rem 2rem 1rem;
  font-size: 2rem;
  font-weight: bold;
}
.sidebar-wrapper .sidebar-header img {
  height: 2.5rem;
}
.sidebar-wrapper .sidebar-toggler.x {
  position: absolute;
  right: 0;
  top: 0.5rem;
  display: none;
}
.sidebar-wrapper .menu {
  padding-left: 0;
  margin-top: 2rem;
}
.sidebar-wrapper .menu .sidebar-title {
  padding: 1.5rem 1.8rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #888;
  text-transform: uppercase;
}
.sidebar-wrapper .menu .sidebar-link {
  display: block;
  padding: 0.7rem 1.5rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  transition: all 0.5s;
}
.sidebar-wrapper .menu .sidebar-link:hover span {
  opacity: 1;
}
.sidebar-wrapper .menu .sidebar-link > span {
  color: #053382;
  font-weight: 500;
  margin-left: 1rem;
  opacity: 0.8;
}
.sidebar-wrapper .menu .sidebar-item {
  list-style: none;
  margin-top: 0.5rem;
  position: relative;
}
.sidebar-wrapper .menu .sidebar-item.has-sub .sidebar-link:after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9" style="fill:none;stroke:gray;stroke-width:1"></polyline></svg>');
  position: absolute;
  color: #ccc;
  right: 15px;
  top: 12px;
  display: block;
}
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link {
  background-color: #e8f3ff;
}
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link:before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(46, 170, 244, 0.5);
  width: 5px;
}
.sidebar-wrapper .menu .submenu {
  list-style: none;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}
.sidebar-wrapper .menu .submenu.active {
  max-height: 500px;
}
.sidebar-wrapper .menu .submenu li a {
  padding: 0.7rem 3rem;
  display: block;
  color: #676767;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.2s;
}
.sidebar-wrapper .menu .submenu li a:hover {
  margin-left: 0.3rem;
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    left: -270px;
  }
  .sidebar-wrapper .sidebar-toggler.x {
    display: block;
  }
}
/** 2.12 Card **/
.card {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  margin-bottom: 2.2rem;
}
.card.card-statistic {
  box-shadow: 1px 2px 5px rgba(47, 170, 244, 0.5);
  background: linear-gradient(to bottom, #25a6f1, #54b9ff);
  border: none;
}
.card.card-statistic .card-title {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.8px;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 5px;
}
.card.card-statistic .card-right p {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0;
}
.card.card-statistic .card-right span.green {
  color: #6fff6f;
}
.card.card-statistic .card-right span.red {
  color: #ff7979;
}
.card.card-statistic .chart-wrapper {
  height: 100px;
}
.card .card-header {
  border: none;
}
.card .card-header h4 {
  font-size: 1.2rem;
}
.card .card-header ~ .card-body {
  padding-top: 0;
}
.card .card-content {
  position: relative;
}
.card .card-title {
  font-size: 1.2rem;
}
.card .card-body {
  padding: 1.4rem 1.7rem;
}
.card .card-heading {
  color: #555;
  font-size: 1.5rem;
}
.card .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.card .card-img-overlay p {
  color: #eee;
}
.card .card-img-overlay .card-title {
  color: #fff;
}

.pricing .card {
  box-shadow: none;
  margin-bottom: 0;
  border-right: 1px solid #e9ecef;
  box-shadow: 0 10px 10px #e9ecef;
  margin-bottom: 0.5rem;
}
.pricing h1 {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 3rem;
}
.pricing .card-header .card-title {
  font-size: 2rem !important;
  margin-bottom: 0;
}
.pricing .card-header p {
  font-size: 0.8rem;
}
.pricing ul li {
  list-style: none;
  margin-bottom: 0.5rem;
}
.pricing ul li i,
.pricing ul li svg {
  width: 1rem;
  color: #39da8a;
  font-size: 1rem;
  margin-right: 7px;
}
.pricing .card-highlighted {
  background-color: #5a8dee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.pricing .card-highlighted .card-header,
.pricing .card-highlighted .card-body {
  background-color: #5a8dee;
  color: #fff;
}
.pricing .card-highlighted ul li {
  color: #fff;
}
.pricing .card-highlighted ul li i,
.pricing .card-highlighted ul li svg {
  color: tint-color(#28a745, 2);
}
.pricing .card-highlighted .card-footer {
  background-color: #5a8dee;
}
.pricing .card-highlighted .card-title {
  color: #fff;
  font-size: 1.8rem;
}

/** 2.13 Navs **/
.nav-pills .nav-link.active {
  box-shadow: 0 2px 10px rgba(90, 141, 238, 0.5);
}

.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  border: none;
}
.nav-tabs .nav-link:hover {
  border: none;
  text-shadow: 0 0 2px rgba(90, 141, 238, 0.3);
}
.nav-tabs .nav-link.active {
  border: none;
  position: relative;
  color: #5a8dee;
}
.nav-tabs .nav-link.active:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #5a8dee;
  left: 0;
  box-shadow: 0 2px 5px rgba(90, 141, 238, 0.5);
}

/** 2.14 Navbar **/
.navbar {
  height: 70px;
  padding: 1.5rem;
}
.navbar .nav-link-user img {
  width: 30px;
}
.navbar.navbar-header li {
  display: flex;
  align-items: center;
}
.navbar.navbar-header li.nav-icon {
  margin-right: 0.4rem;
}
.navbar.navbar-header li.nav-icon .nav-link {
  display: block;
  padding: 0.4rem;
  border-radius: 50%;
}
.navbar.navbar-header li.nav-icon .nav-link:hover {
  background-color: #e9ecef;
}
.navbar.navbar-header .dropdown > a {
  color: #6c757d;
  font-weight: 600;
}
.navbar.navbar-header .dropdown > a svg {
  height: 24px;
  width: 24px;
}
.navbar.navbar-header .dropdown > a:after {
  display: none;
}

/** 2.15 Pagination **/
.pagination.pagination-primary .page-item.active .page-link {
  background-color: #5a8dee;
  border-color: #5a8dee;
  box-shadow: 0 2px 5px rgba(90, 141, 238, 0.3);
}
.pagination.pagination-secondary .page-item.active .page-link {
  background-color: #475f7b;
  border-color: #475f7b;
  box-shadow: 0 2px 5px rgba(71, 95, 123, 0.3);
}
.pagination.pagination-success .page-item.active .page-link {
  background-color: #39da8a;
  border-color: #39da8a;
  box-shadow: 0 2px 5px rgba(57, 218, 138, 0.3);
}
.pagination.pagination-danger .page-item.active .page-link {
  background-color: #ff5b5c;
  border-color: #ff5b5c;
  box-shadow: 0 2px 5px rgba(255, 91, 92, 0.3);
}
.pagination.pagination-warning .page-item.active .page-link {
  background-color: #fdac41;
  border-color: #fdac41;
  box-shadow: 0 2px 5px rgba(253, 172, 65, 0.3);
}
.pagination.pagination-info .page-item.active .page-link {
  background-color: #00cfdd;
  border-color: #00cfdd;
  box-shadow: 0 2px 5px rgba(0, 207, 221, 0.3);
}
.pagination.pagination-dark .page-item.active .page-link {
  background-color: #222f3e;
  border-color: #222f3e;
  box-shadow: 0 2px 5px rgba(34, 47, 62, 0.3);
}
.pagination.pagination-light .page-item.active .page-link {
  background-color: #a3afbd;
  border-color: #a3afbd;
  box-shadow: 0 2px 5px rgba(163, 175, 189, 0.3);
}
.pagination.pagination-link .page-item.active .page-link {
  background-color: #2178d1;
  border-color: #2178d1;
  box-shadow: 0 2px 5px rgba(33, 120, 209, 0.3);
}

.page-item:not(.active) .page-link:hover {
  color: #000;
}
.page-item i,
.page-item svg {
  font-size: 13px;
  width: 13px;
  height: 13px;
}
.page-item .page-link {
  font-size: 0.875rem;
}
.page-item .page-link:focus {
  box-shadow: none;
}
.page-item:first-child {
  margin-right: 0.4rem;
}
.page-item:last-child {
  margin-left: 0.4rem;
}

/** 2.16 Table **/
.table td,
.dataTable-table td,
.table thead th,
.dataTable-table thead th {
  vertical-align: middle;
}

.table thead th,
.dataTable-table thead th {
  border-bottom: 2px solid #475f7b;
}

.dataTable-container {
  overflow-x: auto;
}

/** 2.17 Progress **/
.progress.progress-primary {
  overflow: visible;
}
.progress.progress-primary .progress-bar {
  background-color: #5a8dee;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(90, 141, 238, 0.8);
}
.progress.progress-secondary {
  overflow: visible;
}
.progress.progress-secondary .progress-bar {
  background-color: #475f7b;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(71, 95, 123, 0.8);
}
.progress.progress-success {
  overflow: visible;
}
.progress.progress-success .progress-bar {
  background-color: #39da8a;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(57, 218, 138, 0.8);
}
.progress.progress-danger {
  overflow: visible;
}
.progress.progress-danger .progress-bar {
  background-color: #ff5b5c;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(255, 91, 92, 0.8);
}
.progress.progress-warning {
  overflow: visible;
}
.progress.progress-warning .progress-bar {
  background-color: #fdac41;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(253, 172, 65, 0.8);
}
.progress.progress-info {
  overflow: visible;
}
.progress.progress-info .progress-bar {
  background-color: #00cfdd;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(0, 207, 221, 0.8);
}
.progress.progress-dark {
  overflow: visible;
}
.progress.progress-dark .progress-bar {
  background-color: #222f3e;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(34, 47, 62, 0.8);
}
.progress.progress-light {
  overflow: visible;
}
.progress.progress-light .progress-bar {
  background-color: #a3afbd;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(163, 175, 189, 0.8);
}
.progress.progress-link {
  overflow: visible;
}
.progress.progress-link .progress-bar {
  background-color: #2178d1;
  border-radius: 1rem;
  box-shadow: 0px 2px 3px rgba(33, 120, 209, 0.8);
}
.progress.progress-sm {
  height: 0.4rem;
}
.progress.progress-lg {
  height: 0.8rem;
}
.progress .progress-bar {
  position: relative;
  overflow: visible;
}
.progress .progress-bar.progress-label:before {
  content: attr(aria-valuenow) "%";
  position: absolute;
  right: 0;
  top: -1.3rem;
  color: #495057;
  font-size: 0.8rem;
}

/** 3.1 Chat **/
.chat {
  border-radius: 5px;
}
.chat.chat-left .chat-message {
  background: #5a8dee !important;
  float: left !important;
  color: #fff;
}
.chat .chat-message {
  text-align: left !important;
  float: right !important;
  margin: 0.2rem 0 1.8rem 0.2rem !important;
  color: #525361;
  background-color: #fafbfb !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3) !important;
  padding: 0.75rem 1rem !important;
  position: relative !important;
  max-width: calc(100% - 5rem) !important;
  clear: both !important;
  word-break: break-word !important;
  border-radius: 0.267rem !important;
}

/** 3.1 Todo **/
.widget-todo-list-wrapper {
  padding: 0;
  margin: 0;
}
.widget-todo-list-wrapper .widget-todo-item {
  padding: 0.8rem 2rem 0.8rem 0.8rem;
  list-style: none;
}
.widget-todo-list-wrapper .widget-todo-item:hover {
  background-color: #f8f9fa;
}
.widget-todo-list-wrapper .widget-todo-item .checkbox {
  margin-left: 1rem;
}
.widget-todo-list-wrapper .widget-todo-item i,
.widget-todo-list-wrapper .widget-todo-item svg {
  font-size: 12px;
  cursor: move;
  height: 1rem;
}

/** 4.1 Auth Pages **/
#auth {
  background: #6c757d;
  min-height: 100vh;
  padding-top: 100px;
  /* background-image: url(../img/background/auth.jpg); */
}
#auth .card {
  box-shadow: 0 5px 15px rgba(36, 104, 146, 0.6);
  border: none;
}

/** 4.1 Error Pages **/
#error {
  height: 100vh;
  width: 100%;
  background-color: #e9ecef;
}
#error .error-title {
  font-size: 10rem;
}

/** 5. Utilities **/
.text-xxs {
  font-size: 0.65rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.85rem;
}

.font-bold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.pt-32 {
  padding-top: 6rem;
}

.ml-50 {
  margin-left: 0.5rem;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-10 {
  width: 2.25rem;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-10 {
  height: 2.25rem;
}

.rounded-none {
  border-radius: 0;
}

.round {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 50%;
}

.square {
  border-radius: 0;
}

.text-red {
  color: #ff6384;
}

.bg-red {
  background-color: #ff6384 !important;
}

.text-orange {
  color: #ff9f40;
}

.bg-orange {
  background-color: #ff9f40 !important;
}

.text-yellow {
  color: #ffcd56;
}

.bg-yellow {
  background-color: #ffcd56 !important;
}

.text-green {
  color: #4bc0c0;
}

.bg-green {
  background-color: #4bc0c0 !important;
}

.text-info {
  color: #41b1f9;
}

.bg-info {
  background-color: #41b1f9 !important;
}

.text-blue {
  color: #3245d1;
}

.bg-blue {
  background-color: #3245d1 !important;
}

.text-purple {
  color: #9966ff;
}

.bg-purple {
  background-color: #9966ff !important;
}

.text-grey {
  color: #ebeff6;
}

.bg-grey {
  background-color: #ebeff6 !important;
}

.text-gray-700 {
  color: #4a5568;
}

.bg-gray-700 {
  background-color: #4a5568 !important;
}

#custom-search-input {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

#custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 3px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */

  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: relative;
  left: -28px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #d9230f;
}

.search-query:focus + button {
  z-index: 3;
}

.container.fill_Container {
  position: inherit;
  padding-top: 117px;
}

/*Edit Profile*/

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px !important;
}

/* STEPER */
.wrapper-progressBar {
  width: 100%;
}
.progressBar {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 10px;
  position: relative;
}

.progressBar li {
  list-style-type: none;
  /* float: left; */
  display: inline-flex;
  width: 25%;
  justify-content: center;
  position: relative;
  /* flex-direction: row; */
  /* flex: 1; */
  /* width: 25%; */
  /* position: relative;
  text-align: center; */
}
.progressBar li span {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background-color: white;
}
.progressBar li.active span {
  background-color: dodgerblue;
  color: #fff !important;
  border-color: dodgerblue;
}

.progressBar li:after {
  content: "";
  position: absolute;
  width: 150%;
  top: 50%;
  left: -100%;
  height: 7px;
  z-index: 0;
  transform: translateY(-50%);
  background-color: #ddd;

  /* left: -50%; */
}
.progressBar li::before {
  /* content: "";
  position: absolute;
  width: 150%;
  top: 50%;
  left: 50%;
  height: 7px;
  z-index: 0;
  transform: translateY(-50%);
  background-color: #ddd; */

  /* left: -50%; */
}

.progressBar li:first-child:after,
.progressBar li:last-child::before {
  content: none;
}

.progressBar li.active {
  color: dodgerblue;
}

.progressBar li.active:before {
  border-color: dodgerblue;
  background-color: #1e90ff;
  color: white;
}

.progressBar li.active::after {
  background-color: dodgerblue;
}

/*CHAT*/

.container {
  max-width: 1170px;
  margin: auto;
}
img {
  max-width: 100%;
}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
  /* padding: ; */
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 57%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
/*Events Page*/
.border.rounded.eve_Section {
  padding: 50px 60px;
}
.border.rounded.eve_Section p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  padding: 10px;
}
.border.rounded.eve_Section a.btn.btn-primary {
  padding-top: 10px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  padding: 7px 70px;
  margin-top: 40px;
}

/*Event Detail Page*/

.mbm_social {
  padding: 8px 0px;
  font-size: 0;
  width: 100%;
  background: #fff;
}
.mbm_social li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 8px 0 0;
  padding: 0;
  font-family: "Rubik", sans-serif !important;
  text-transform: uppercase;
}
.mbm_social > li:last-child {
  margin: 0;
}
.mbm_social a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 px;
  width: 116px;
  height: 31px;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  z-index: 2;
  font-family: "Rubik", sans-serif !important;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mbm_social a:hover {
  color: #fff;
}
.mbm_social a:hover .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, -33px);
  transform: translate(0, -33px);
}
.mbm_social a:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.mbm_social .tooltip {
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 50%;
  z-index: 1;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mbm_social .tooltip span {
  font-size: 10px;
  font-weight: bold;
  left: -50%;
  line-height: 1;
  padding: 6px 8px 5px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.mbm_social .tooltip span:after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border: 8px solid transparent;
}
.mbm_social .social-twitter {
  background: #00abdc;
  background: -webkit-linear-gradient(#00abdc, #00abdc);
  background: linear-gradient(#00abdc, #00abdc);
  border-bottom: 1px solid #00abdc;
}
.mbm_social .social-twitter:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #00abdc;
}
.mbm_social .social-twitter span {
  background: #00abdc;
  background: -webkit-linear-gradient(#00abdc, #00abdc);
  background: linear-gradient(#00abdc, #00abdc);
  color: #fff;
}
.mbm_social .social-twitter span:after {
  border-top-color: #00abdc;
}
.mbm_social .social-linkedin {
  background: #286580;
  background: -webkit-linear-gradient(#286580, #286580);
  background: linear-gradient(#286580, #286580);
  border-bottom: 1px solid #286580;
}
.mbm_social .social-linkedin:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #286580;
}
.mbm_social .social-linkedin span {
  background: #286580;
  background: -webkit-linear-gradient(#286580, #286580);
  background: linear-gradient(#286580, #286580);
  color: #fff;
}
.mbm_social .social-linkedin span:after {
  border-top-color: #286580;
}
.mbm_social .social-facebook {
  background: #325c94;
  background: -webkit-linear-gradient(#4562a0, #385693);
  background: linear-gradient(#4562a0, #385693);
  border-bottom: 1px solid #2f487c;
}
.mbm_social .social-facebook:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #2f487c;
}
.mbm_social .social-facebook span {
  background: #3b5a9b;
  background: -webkit-linear-gradient(#5873aa, #3b5a9b);
  background: linear-gradient(#5873aa, #3b5a9b);
  color: #fff;
}
.mbm_social .social-facebook span:after {
  border-top-color: #325c94;
}
.mbm_social .social-google-plus {
  background: #ea4335;
  background: -webkit-linear-gradient(#ea4335, #ea4335);
  background: linear-gradient(#ea4335, #ea4335);
  border-bottom: 1px solid #ea4335;
}
.mbm_social .social-google-plus:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #ea4335;
}
.mbm_social .social-google-plus span {
  background: #ea4335;
  background: -webkit-linear-gradient(#ea4335, #ea4335);
  background: linear-gradient(#ea4335, #ea4335);
  color: #fff;
}
.mbm_social .social-google-plus span:after {
  border-top-color: #ea4335;
}
.card-body.MYForm .form-group label {
  font-size: 16px;
  text-transform: normal;
  color: rgb(6 6 6 / 70%);
  font-weight: 500;
}
.card-body.myAdress p {
  font-size: 16px;
  text-transform: normal;
  color: rgb(6 6 6 / 70%);
  font-weight: 500;
}

.form-group label {
  text-transform: normal;
  color: rgba(35, 28, 99, 0.7);
  font-weight: 500;
  font-size: 14px;
}
.mbm_social i {
  position: relative;
  top: -2px;
  font-size: 14px;
}
.card-body.MYForm {
  margin: 30px;
}
.card-body.myAdress {
  margin: 35px;
}
.mbm_social small {
  font-size: 14px;
  margin: 0 0 0 9px;
  vertical-align: middle;
}
