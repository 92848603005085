.footer-container{
    background-color: #000000;
    
}
.menu-item-1 ul li{
list-style-type: none;
color:white;
display: inline;


}
.menu-item-1{
    color:white;
    
}
.footer-logo{
    height:70px;
}
.menu-item-1 ul a:hover{
    text-decoration: none;
    cursor: pointer;
    
    }
    .menu-item-2  a:hover{
        text-decoration: none;
        cursor: pointer;
        
        }