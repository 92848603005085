/*About us*/
.aboutus {
  background-image: url("../../images/IMG_1435.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 8% 0%;
}
.col-lg-12.aboutUs h1 {
  color: #fff;
  text-transform: uppercase;
}
