@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
:root {
  --main-color: #ece3e2;
  --accent-color: #0d1b46;
  --dark: #65bcbf;
  --lightgreen: #65bcbf !important;
  --pink: #f8777d;
  --info: #4f0170 !important;
}
.why_connect_div {
  min-height: 100vh;
}
.btn-info {
  background-color: var(--info);
  border-color: var(--info);
}
.btn-info:disabled {
  background-color: var(--lightgreen);
  border-color: var(--lightgreen);
}
.btn-primary {
  background-color: var(--info);
  border-color: var(--info);
}
html,
body {
  font-family: "Rubik", sans-serif !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100vw;
  scroll-behavior: smooth;
  background-color: #c5e2e0;
  min-height: 100vh;
}

h1 {
  text-align: center;
}

#navbar {
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-size: 1.5em;
  min-height: 2.5em;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.3);
  color: var(--accent-color);
  top: 0;
  position: sticky;
  background-color: #c5e2e0;
  z-index: 1;
}

#navpart1 {
  display: flex;
  flex-direction: row;
  width: 75%;
}

#navpart1 div {
  padding-top: 0.5em;
  padding-right: 2em;
  padding-left: 1em;
}

#navpart2 {
  display: flex-end;
  flex-direction: row;
  padding-top: 0.5em;
  margin-left: auto;
}

#navbar a {
  text-decoration: none;
  color: inherit;
}

#navbar a:hover {
  background-color: var(--accent-color);
  color: white;
}

button {
  font-family: "Rubik", sans-serif !important;
  font-weight: bold;
  font-size: 1.5rem;
  padding-left: 1em;
  padding-right: 1em;

  border-radius: 5px;
  border-style: none;
}

.redButton,
.greyButton:hover {
  background-color: var(--accent-color);
  color: var(--main-color);
}

.greyButton,
.redButton:hover {
  background-color: var(--main-color);
  color: var(--accent-color);
}

#regions_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
}
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
}
#regions_div g rect {
  fill: #c5e2e0 !important;
}

#preview {
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-top: 10%;
  margin-left: 2%;
  margin-right: 2%;
}

.fly {
  margin: 2em auto;
  opacity: 0;
  transition: transform 1s 0.15s, opacity 0.3s 0.25s ease-out;
  width: 90%;
}

.fly {
  opacity: 1;
  transform: translateY(-20%);
}

.information {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 3%;
  margin-top: 5%;
  padding: 2%;
  border-radius: 5px;
  border-style: solid;
  border: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.3);
  background: #d1e6e5;
}

.information div {
  padding: 5%;
}

footer {
  background-color: var(--accent-color);
  color: var(--main-color);
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

ul {
  list-style: none;
  margin-left: -2em;
}

li i {
  font-size: 1em !important;
  margin-right: 0.25em;
  color: var(--accent-color);
}

.flip-card {
  background-color: transparent;
  width: 30%;
  height: 50vh;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: var(--main-color);
  color: var(--accent-color);
  transform: rotateY(180deg);
}

.flip-card-back div {
  padding: 15%;
}
.flip-card-front-mbl,
.flip-card-back-mbl {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* -webkit-backface-visibility: hidden; */
  backface-visibility: hidden;
}

.flip-card-front-mbl {
  background-color: #bbb;
  color: black;
}

.flip-card-back-mbl {
  background-color: var(--main-color);
  color: var(--accent-color);
  transform: rotateY(180deg);
}

.flip-card-back-mbl div {
  padding: 15%;
}

.flip-card-mobile {
  background-color: transparent;
  width: 100%;

  perspective: 1000px;
}

.flip-card-mobile:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-img {
  width: 100%;
  height: 100%;
}
.flip-text {
  font-size: 14px;
  line-height: 25px;
  color: #0d1b46;
}
/* @media (max-width: 768px) {
 .flip-card {
    background-color: transparent;
    width: 100%;
    
    perspective: 1000px; 
}   
  } */
/* .btn {
  height: 40px;
} */
/* .reactEasyCrop_Container {
  width: auto !important;
  height: auto !important;
} */

.bg-color {
  background-color: #c9e3e2 !important;
}
.text-indigo {
  color: #4f0170;
}
.text-light-grey {
  color: #f7f7f7;
}

.Footer {
  background: #4f0170;
  /* margin-top: 100px; */
}
#preview {
  margin-top: 20px !important;
}
.myBox {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  background: #41ccc7 !important;
}
.profile-card {
  background: rgba(255, 255, 255, 0.35);
}
.modal .modal-header .close {
  padding: 0 !important;
  margin: 0 !important;
}
.suggestedpost-text {
  line-height: 18px;
  font-weight: 500;
  font-size: 18px;
  text-align: justify;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.suggestedpost-link {
  line-height: 13px;
  font-weight: 600;
  font-size: 13px;
  color: #bdbdbd !important;
  text-align: justify;
}
.suggestedpost-user-img {
  width: 70px;
  height: 70px;
}
#nearme {
  width: 30%;
}
.search-image-filter {
  width: 120px;
  height: 120px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

.post-carousel .carousel-inner .carousel-item {
  width: 100%;
  height: 175px;
  align-items: "center";
}

.carousel-inner {
  box-shadow: none;
}
.MuiSvgIcon-root {
  font-size: 1.4em !important;
}
.carousel-item {
  height: 175px;
}
@media (max-width: 768px) {
  h4 {
    font-size: 1.15rem;
  }
  h5 {
    font-size: 1rem;
  }
  .btn {
    font-size: 0.9rem;
  }
  .search-image-filter {
    width: 78.75px;
    height: 78.75px;
  }
  #nearme {
    width: 43%;
  }
  .why_connect_div {
    min-height: 15vh;
    margin-top: 5vh;
  }
  .hero {
    height: 40vh !important;
  }
  .text-sm-white {
    color: white;
  }
}
