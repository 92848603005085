/* menu-bar */
.menu-bar {
  background-color: #f7faff;
  height: fit-content;
}
.menu-items {
  color: #90cce7 !important;
  list-style-type: none;
  display: inline;
  padding-left: 10px;
  /* height:50px !important; */

  opacity: 1;
}
.toggle-button {
  position: fixed;
  top: 10px;
  z-index: 100;
}
.event:hover img {
  scale: 1;
}
.minHeight {
  min-height: 70vh;
}
@media (max-width: 786px) {
  .menu-items {
    display: list-item;
    height: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.bar {
  background-color: #f7faff;
}

.logo-image {
  background-image: url("./assets/images/logo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
}
.menu-items:hover {
  color: #235a74 !important;
  cursor: pointer;
}
/*Index page*/
.images .card-body.p-0.myBox {
  text-align: center !important;
  padding: 50px 0px !important;
}

.form-area.My_Area {
  padding: 2% 5%;
}

#custom-search-input {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

#custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 3px;
  padding-left: 4px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */

  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 2px 5px;
  margin-top: 2px;
  position: relative;
  left: -28px;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #d9230f;
}

.search-query:focus + button {
  z-index: 3;
}
/*Edit Profile*/

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

/*CHAT*/

.container {
  max-width: 1170px;
  margin: auto;
}
img {
  max-width: 100%;
}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}
.inbox_people_mobile {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 100%;
  border-right: 1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 50px;
  height: 50px;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.incoming_msg_img_mobile {
  display: inline-block;
  width: 12%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_msg_mobile {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 86%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 57%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}
.mesgs_mobile {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
}

.sent_msg p {
  background: #41ccc7 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #41ccc7 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;

  top: 11px;
  width: 33px;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
/*Events Page*/
.border.rounded.eve_Section {
  padding: 0;
}
.border.rounded.eve_Section p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  padding: 10px;
}
.border.rounded.eve_Section a.btn.btn-primary {
  padding-top: 10px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  padding: 7px 70px;
  margin-top: 40px;
}

/*Event Detail Page*/

.mbm_social {
  padding: 8px 0px;
  font-size: 0;
  width: 100%;
  background: #fff;
}
.mbm_social li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 8px 0 0;
  padding: 0;
  font-family: "Rubik", sans-serif !important;
  text-transform: uppercase;
}
.mbm_social > li:last-child {
  margin: 0;
}
.mbm_social a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 px;
  width: 116px;
  height: 31px;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  z-index: 2;
  font-family: "Rubik", sans-serif !important;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mbm_social a:hover {
  color: #fff;
}
.mbm_social a:hover .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, -33px);
  transform: translate(0, -33px);
}
.mbm_social a:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.mbm_social .tooltip {
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 50%;
  z-index: 1;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mbm_social .tooltip span {
  font-size: 10px;
  font-weight: bold;
  left: -50%;
  line-height: 1;
  padding: 6px 8px 5px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.mbm_social .tooltip span:after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border: 8px solid transparent;
}
.mbm_social .social-twitter {
  background: #00abdc;
  background: -webkit-linear-gradient(#00abdc, #00abdc);
  background: linear-gradient(#00abdc, #00abdc);
  border-bottom: 1px solid #00abdc;
}
.mbm_social .social-twitter:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #00abdc;
}
.mbm_social .social-twitter span {
  background: #00abdc;
  background: -webkit-linear-gradient(#00abdc, #00abdc);
  background: linear-gradient(#00abdc, #00abdc);
  color: #fff;
}
.mbm_social .social-twitter span:after {
  border-top-color: #00abdc;
}
.mbm_social .social-linkedin {
  background: #286580;
  background: -webkit-linear-gradient(#286580, #286580);
  background: linear-gradient(#286580, #286580);
  border-bottom: 1px solid #286580;
}
.mbm_social .social-linkedin:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #286580;
}
.mbm_social .social-linkedin span {
  background: #286580;
  background: -webkit-linear-gradient(#286580, #286580);
  background: linear-gradient(#286580, #286580);
  color: #fff;
}
.mbm_social .social-linkedin span:after {
  border-top-color: #286580;
}
.mbm_social .social-facebook {
  background: #325c94;
  background: -webkit-linear-gradient(#4562a0, #385693);
  background: linear-gradient(#4562a0, #385693);
  border-bottom: 1px solid #2f487c;
}
.mbm_social .social-facebook:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #2f487c;
}
.mbm_social .social-facebook span {
  background: #3b5a9b;
  background: -webkit-linear-gradient(#5873aa, #3b5a9b);
  background: linear-gradient(#5873aa, #3b5a9b);
  color: #fff;
}
.mbm_social .social-facebook span:after {
  border-top-color: #325c94;
}
.mbm_social .social-google-plus {
  background: #ea4335;
  background: -webkit-linear-gradient(#ea4335, #ea4335);
  background: linear-gradient(#ea4335, #ea4335);
  border-bottom: 1px solid #ea4335;
}
.mbm_social .social-google-plus:hover {
  color: #fff;
  text-shadow: 0px 1px 0px #ea4335;
}
.mbm_social .social-google-plus span {
  background: #ea4335;
  background: -webkit-linear-gradient(#ea4335, #ea4335);
  background: linear-gradient(#ea4335, #ea4335);
  color: #fff;
}
.mbm_social .social-google-plus span:after {
  border-top-color: #ea4335;
}
.card-body.MYForm .form-group label {
  font-size: 16px;
  text-transform: normal;
  color: rgb(6 6 6 / 70%);
  font-weight: 500;
}
.card-body.myAdress p {
  font-size: 16px;
  text-transform: normal;
  color: rgb(6 6 6 / 70%);
  font-weight: 500;
}

.form-group label {
  color: rgba(35, 28, 99, 0.7);
  font-weight: 500;
  font-size: 14px;
}
.mbm_social i {
  position: relative;
  top: -2px;
  font-size: 14px;
}
.card-body.MYForm {
  margin: 30px;
}
.card-body.myAdress {
  margin: 35px;
}
.mbm_social small {
  font-size: 14px;
  margin: 0 0 0 9px;
  vertical-align: middle;
}
.span1 img {
  max-width: 12%;
  width: 12%;
}
.sidebar-header img {
  width: 50%;
  height: 50% !important;
}
@media screen and (max-width: 767px) {
  .span1 img {
    max-width: 30%;
    width: 30%;
  }
  .span3 .btn.btn-success {
    color: #fff;
    margin-top: 10px;
  }
}
.circular {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.circular2 {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  position: relative;
  overflow: hidden;
}
.circular img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.circular2 img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.red-dot-nav {
  font-size: 10px !important;
}
.reactEasyCrop_Container {
  width: 350px;
  height: 350px;
}
.dashboard-connect-img {
  width: 150 !important;
  height: 150 !important;
}
.img-div-cust-width {
  width: 50%;
}
@media (min-width: 992px) {
  .img-div-cust-width {
    width: 25% !important;
  }
}
