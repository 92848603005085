html {
  font-family: "Rubik", sans-serif !important;
}
/* @font-face {
  font-family: 'Rubik', sans-serif !important;
	src: url('fonts/notoserif-regular.woff?#iefix') format('embedded-opentype'),  url('fonts/notoserif-bold.woff') format('woff'), url('fonts/notoserif-bold.woff2')  format('truetype'), url('fonts/notoserif-regular') format('svg');
	 src: url('fonts/roboto-black.woff?#iefix') format('embedded-opentype'),  url('fonts/roboto-bold.woff') format('woff'), url('fonts/roboto-blackitalic.woff')  format('truetype'), url('fonts/notoserif-regular') format('svg');
	 src: url('fonts/Amaticsc-Regular.woff?#iefix') format('embedded-opentype'),  url('fonts/Amaticsc-Regular.woff2') format('woff'), url('fonts/Amaticsc-Bold.woff')  format('truetype'), url('fonts/Amaticsc-Bold.woff') format('svg');
  font-style: normal;
} */
body {
  font-family: "Rubik", sans-serif !important;
}
.event:hover img {
  transform: scale(1.01, 1.01);
  opacity: 0.8;
}
.wrapper {
  background: #c5e2e0;
  padding: 0px;
}
.wrapper_sign {
  padding: 0px;
  z-index: 999;
}
.container.header {
  line-height: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif !important;
}
/*Header section*/
nav {
  width: 100%;
  line-height: 80px;
  z-index: 999;
}
.main_Banner {
  position: relative;
  min-height: 500px;
  background: url(../images/banners.jpg) 0% 0% / 100% 100% no-repeat
    rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
}
.signUp_Banner {
  position: relative;
  min-height: 500px;
  padding-bottom: 60px;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.col-sm-6.logo img {
  width: 15%;
  max-width: 30%;
}
.col-sm-6.signIn {
  text-align: right;
}
.col-sm-6.SignUp {
  padding-top: 15px;
}
.col-sm-6.SignUp input#email,
input#pwd {
  border-radius: 0px;
  margin-right: 5px;
  border: none;
  background: #8080803d;
}
.FotmBottom {
  display: flex;
}

.checkbox1 {
  line-height: 41px;
  padding-left: 70px;
}
.checkbox1 label {
  color: #fff;
}
.col-sm-6.SignUp a {
  color: #fff;
  text-decoration: none;
}
.col-sm-6.SignUp a {
  color: #fff;
  text-decoration: none;
  line-height: 40px;
}
.form-check-label {
  color: #fff;
}
a.header_BtnBtn {
  background: #4f0170;
  border-color: #4f0170;
  font-size: 1.25rem;
  padding: 14px 22px;
  margin-left: 10px;
  line-height: 2.5;
  font-family: "Rubik", sans-serif !important;
  border-radius: 50px;
  color: #fff;
}
a.header_BtnBtn:hover {
  background: #3d0157;
  text-decoration: none;
  color: #fff;
}
.col-sm-12.header_Heading {
  text-align: center;
  padding-top: 16%;
}
.col-sm-12.header_Heading h1 {
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

/*Beruit City Section*/
.container.top_Sec {
  text-align: center;
}
.row.topsec_bottom {
  padding-top: 50px;
}
.container.top_Sec h2 {
  font-family: "Rubik", sans-serif !important;
  text-align: center;
  padding-top: 7%;
  font-weight: 600;
  font-size: 30px;
}
.top_Sec img {
  vertical-align: middle;
  border-style: none;
  width: 25%;
  max-width: 25%;
}
/*Why Amindset*/
.container.beruit_Sec img {
  width: 100%;
  max-width: 100%;
}
.container.beruit_Sec h3 {
  text-align: center;
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
  padding-top: 60px;
  font-size: 30px;
}
h6.text-left1 {
  font-family: "Rubik", sans-serif !important;
  font-weight: 600 !important;
}
.text-left1 {
  padding-left: 22px;
  text-align: left !important;
}
.badge-secondary {
  color: #333 !important;
}

span.badge-secondary.rounded-circle {
  width: 100%;
  height: 100%;
}
span.b-avatar-text {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(231, 217, 244);
}
span.b-avatar-text2 {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(240, 238, 166) !important;
}
span.b-avatar-text3 {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(138, 225, 210) !important;
}
span.b-avatar-text4 {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(239, 177, 184) !important;
}
span.b-avatar-text5 {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(239, 238, 177) !important;
}
span.b-avatar-text6 {
  padding: 8px 14px;
  border-radius: 50%;
  background: rgb(177, 239, 196) !important;
}
/*Skip the way*/
.skip_way {
  margin-top: 80px;
}
.container.skkp_Sec {
  text-align: center;
  padding: 50px;
}
.container.skkp_Sec h3 {
  font-weight: 600;
}
.container.testimonial_Sec {
  text-align: center;
  padding: 80px;
}
.container.testimonial_Sec h3 {
  font-weight: 600;
}
/*Carousel Indicator*/
.carousel-indicators li {
  border-top: 4px solid #333 !important;
  border-bottom: 0px solid #333 !important;
  width: 20px !important;
  height: opx !important;
}
.carousel-inner h5 {
  margin-bottom: 50px;
}
/*Footer*/
.container.Footer {
  text-align: center;
  padding: 40px;
}
.container.Footer .Socail_icon {
  text-align: center;
}
.col-sm-12.Socail_icon {
  font-size: 25px;
}
.col-sm-12.Socail_icon p {
  font-size: 16px;
}
.container.Footer .active,
.btn:hover {
  background: #4f0170 !important;
  color: white;
}

/*Sign up page*/
.col-sm-6.Left_bannerSection h1 {
  font-family: "Rubik", sans-serif !important;
  font-size: 30px;
  text-transform: uppercase;
  color: #333;
}
.row.FormSection {
  padding-top: 10%;
}
.card-body.p-3.customCard {
  padding: 10px 70px !important;
}

.col-sm-6.Left_bannerSection p {
  padding-top: 45px;
  color: #333;
  font-size: 19px;
  text-align: center;
}
.banner_icon {
  display: flex;
  text-align: center;
}
.banner_icon img {
  width: 30%;
  max-width: 30%;
}
.banner_icon P {
  font-family: "Rubik", sans-serif !important;
  font-size: 16px !important;
  padding-top: 13px !important;
  text-transform: uppercase;
  color: #333;
  font-weight: 500;
}
.card.border-primary.rounded-0.bg {
  background: #24303e;
}

.social-btns.center-block {
  display: inline-flex;
}
input#log_email,
input#log_pwd {
  margin-right: 7px;
}
button.btn.btn-primary.sign_login {
  background: #25c0a1;
}
.social-btns.center-block .btn {
  color: #ffffff;
  background: #5a78ae;
  margin-right: 10px;
}
.bg-info {
  background-color: #c5e2e0 !important;
}
.social-btns {
  font-size: 22px;
  list-style: none;
}

.social-btns li {
  margin-bottom: 5px;
}

.social-btns li i {
  font-size: 22px;
}

.social-btns .btn-facebook {
  background-color: #38519f;
  border-color: #293b75;
  color: #ffffff;
}

.social-btns .btn-facebook:hover {
  color: #ffffff;
  background-color: #293b75;
}
.fa.pull-left {
  margin-top: 4px;
}
.form-control {
  background-color: #fff !important;
}
input[type="checkbox"] {
  margin-top: 7px;
}
input.btn.btn-info.btn-block.rounded-0.py-2 {
  background: #1bbc9b;
  margin: 35px 0px;
}
/*Fill Profile*/
a.btn.btn-circle.btn-default.btn-primary {
  border-radius: 50px;
  padding: 3px;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .card-body.p-3.customCard {
    padding: 10px 20px !important;
  }
  .social-btns.center-block .btn {
    margin-right: 5px;
  }
  .social-btns.center-block .btn {
    font-size: 11px;
  }
  .col-sm-6.SignUp a {
    font-size: 14px;
  }
  .checkbox1 label {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  a.header_BtnBtn {
    font-size: 1rem;
    padding: 7px 11px;
    margin-left: 5px;
  }
  .main_Banner {
    min-height: 390px;
  }
  .col-sm-12.header_Heading {
    text-align: center;
    padding-top: 10%;
  }
  .col-sm-6.logo {
    text-align: center;
  }
  .col-sm-6.signIn {
    text-align: center;
  }
  nav {
    position: relative;
  }
  .container.top_Sec h5 {
    padding-top: 15%;
  }
  .col-sm-12.header_Heading h1 {
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .container.top_Sec h2 {
    padding-top: 20%;
  }
  .container.beruit_Sec h3 {
    padding-top: 45px;
  }
  .col-sm-7.Left_bannerSection h1 {
    font-size: 30px;
  }
  .col-sm-6.SignUp input#email,
  input#log_pwd {
    margin-bottom: 5px;
  }
  .card-body.p-3.customCard {
    padding: 15px 30px !important;
  }

  .mt-sm-160px {
    margin-top: 160px;
  }

  .social-btns.center-block .btn {
    margin-right: 2px;
  }
  input#pwd {
    margin-right: 0px;
  }
  .container.Footer {
    text-align: center;
    padding: 28px 15px;
  }
  .signUp_Banner {
    background: url(../images/signupBanner.png);
    background-size: cover;
  }
  .container.Footer .btn-light {
    margin-left: 5px;
    margin-bottom: 10px;
  }
}
