.top_Sec img:hover {
  transform: scale(1.1);
}

.top_Sec img {
  transition: all 0.2s ease-in-out;
}

.information {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 3%;
  margin-top: 5%;
  padding: 2%;
  border-radius: 5px;
  border-style: solid;
  border: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.3);
}

.information div {
  padding: 5%;
}
#preview {
  text-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-height: 100vh;
  gap: 3%;
  margin-top: 10%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 5%;
}
.fly {
  margin: 2em auto;
  opacity: 0;
  transition: transform 1s 0.15s, opacity 0.3s 0.25s ease-out;
  width: 90%;
}

.fly {
  opacity: 1;
  transform: translateY(-20%);
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: auto;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: var(--main-color);
  color: var(--accent-color);
  transform: rotateY(180deg);
}

.flip-card-back div {
  padding: 15%;
}
.flip-card-front-mbl,
.flip-card-back-mbl {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-mbl {
  background-color: #bbb;
  color: black;
}

.flip-card-back-mbl {
  background-color: var(--main-color);
  color: var(--accent-color);
  transform: rotateY(180deg);
}

.flip-card-back-mbl div {
  padding: 15%;
}

.flip-img {
  width: 100%;
  height: 100%;
}
#forumPage {
  /* display: grid; */
  gap: 5%;
}

#forum {
  margin: 2em;
}

.arrows {
  font-size: 2em;
}

#question,
.answer {
  display: flex;
  flex-direction: row;
}

#question,
.questionText {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 0.5em;
  height: fit-content;

  border-style: solid;
  border: 1px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.3);
}

.answer {
  margin-top: 2em;
  margin-left: 3em;
}
.mostVoted {
  background-color: var(--lightgreen);
}
